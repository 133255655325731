// @flow
import * as React from "react";
import ClientSection from "../../components/sections/client-section";
import SubscribeSection from "../../components/sections/subscribe-section";
import axiosconfig from "../../config/axios-config";
import Head from "next/head";
import Testimonial from "../../components/layout/dynamic-pages/testimonial";
import Brochures from "../../components/page module/Brochures";
import { FindAgent } from "../../components/layout/dynamic-pages/find-agent";
import Careers from "../../components/careers/career";
import Link from "next/link";
import Special from "../../components/page module/Special offers";
import Contact from "../../components/page module/Contact Us";
import Term from "../../components/page module/Terms";
import Request from "../../components/page module/RequestQuote";
import { formatCurrencyWithoutFraction } from "../../utility/helper";

export default function Page({
  pageContainerData,
  featuresData,
  subpageContainerData,
  pageUrlModule,
}) {
  return (
    <>
      <Head>
        <meta
          name="description"
          content={pageContainerData?.pageSEO?.pageDescription}
        />
        <meta
          name="keywords"
          content={pageContainerData?.pageSEO?.pageKeywords}
        />

        {pageContainerData?.pageOG !== undefined &&
          pageContainerData?.pageOG.length > 0 &&
          pageContainerData?.pageOG.map((og) => {
            <meta property={og.propertyName} content={og.propertyValue} />;
          })}

        <title>{pageContainerData?.pageSEO?.pageTitle}</title>
      </Head>
      <div id="page" className="full-page">
        <main id="content" className="site-main">
          {/* <!-- Inner Banner html start--> */}
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container lg d-flex flex-column justify-content-center"
              style={{
                backgroundImage: `url(${
                  pageContainerData?.pageContent?.headerPhoto
                    ? pageContainerData?.pageContent?.headerPhoto
                    : "/inner-banner.jpg"
                })`,
              }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">
                    {pageContainerData?.pageContent?.title}
                  </h1>
                  <h4 className="text-white">
                    {" "}
                    {pageContainerData?.pageContent?.subTitle}
                  </h4>
                </div>
              </div>
            </div>
            <div className="inner-shape"></div>
          </section>
          {/* <!-- Inner Banner html end--> */}
          <section className="about-section about-page-section py-5">
            <div className="about-service-wrap">
              <div className="container-wrap">
                <div className="container">
                  <div className="row d-flex align-items-center mb-5">
                    {pageContainerData?.shortDescriptionImage ? (
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-5">
                        <img
                          className="img img-fluid w-100"
                          src={pageContainerData?.shortDescriptionImage}
                          alt="Central Holidays"
                        ></img>
                      </div>
                    ) : null}
                    <div
                      className={
                        pageContainerData?.shortDescriptionImage
                          ? "col-xl-10 col-lg-9 col-md-8 col-sm-7"
                          : "col-12"
                      }
                    >
                      {pageContainerData?.pageContent?.description && (
                        <p
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: pageContainerData?.pageContent?.description,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                </div>
                {subpageContainerData.length > 0 && (
                  <div className="package-section newsroom-cards-section">
                    <div className="container">
                      <div className="package-inner">
                        <div className="row">
                          {subpageContainerData
                            ?.filter((s) => s.isActive)
                            ?.map((subpage) => (
                              <>
                                <div
                                  className={`col-lg-4 col-md-6 order-${subpage.order}`}
                                >
                                  <div className="package-wrap newsroom-card">
                                    <figure className="feature-image">
                                      <Link
                                        href={`/${pageUrlModule}/${subpage?.pageSEO?.pageURL}`}
                                      >
                                        <a>
                                          <img
                                            src={
                                              subpage?.pageContent.headerPhoto
                                                ? subpage.pageContent
                                                    .headerPhoto
                                                : "/inner-banner.jpg"
                                            }
                                            alt=""
                                          />
                                        </a>
                                      </Link>
                                    </figure>
                                    <div className="newsroom-title">
                                      <h4>
                                        <Link
                                          href={`/${pageUrlModule}/${subpage?.pageSEO?.pageURL}`}
                                        >
                                          <a>
                                            {" "}
                                            <strong>
                                              {subpage?.pageContent.title}
                                            </strong>{" "}
                                          </a>
                                        </Link>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {pageContainerData?.sections?.map((section, index) => {
                  return (
                    <>
                      {!section?.isPackageSection && (
                        <>
                          {section.layout === "0" && (
                            <section
                              className={
                                index % 2 === 0 &&
                                pageContainerData?.sections?.length > 1
                                  ? "bg-light py-80"
                                  : "bg-white"
                              }
                            >
                              <div className="container">
                                <div key={section.id} className="row py-3">
                                  <div className="col-12">
                                    <h3 className="fw-700">{section.title}</h3>
                                    {section?.subTitle && (
                                      <p className="text-main fs-15 fw-500">
                                        {section?.subTitle}
                                      </p>
                                    )}
                                  </div>
                                  <div
                                    className="col-lg-12"
                                    dangerouslySetInnerHTML={{
                                      __html: section.body,
                                    }}
                                  ></div>
                                  {(section?.firstButtonLink ||
                                    section?.secondButtonLink) && (
                                    <div className="action px-3 mt-4">
                                      {section?.firstButtonLink && (
                                        <a
                                          className="btn btn-danger text-white px-4 mr-2"
                                          href={section.firstButtonLink}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {section.firstButtonText
                                            ? section.firstButtonText
                                            : "Click Here"}
                                        </a>
                                      )}
                                      {section?.secondButtonLink && (
                                        <a
                                          className="btn btn-outline-dark px-4"
                                          href={section?.secondButtonLink}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {section?.secondButtonText
                                            ? section?.secondButtonText
                                            : "Click Here"}
                                        </a>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </section>
                          )}
                          {section.layout === "1" && (
                            <section
                              key={section.id}
                              className={`info-section px-lg-0 mb-4 ${
                                index % 2 === 0 &&
                                pageContainerData?.sections?.length > 1
                                  ? "bg-light py-80"
                                  : "bg-white"
                              }`}
                              id="infoSection"
                            >
                              <div className="container">
                                <div className="row g-5">
                                  <div
                                    className={
                                      section?.smallImage
                                        ? "col-lg-3 mb-4"
                                        : "col-lg-6 mb-4"
                                    }
                                  >
                                    <div className="image text-left">
                                      <img
                                        className="img img-fluid rounded"
                                        src={section.photo ? section.photo : ""}
                                        alt="Central Holidays"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={`${
                                      section?.smallImage
                                        ? "col-lg-9 pl-lg-4"
                                        : "col-lg-6 pl-lg-5"
                                    } d-flex align-items-center`}
                                  >
                                    <div className="text px-0">
                                      <div className="head mx-w-auto mb-3">
                                        <h3
                                          className={`text-dark fs-30 fw-700 ${
                                            section?.subTitle ? "mb-1" : ""
                                          }`}
                                        >
                                          {section.title}
                                        </h3>
                                        {section?.subTitle && (
                                          <p className="text-main fs-15 fw-500">
                                            {section?.subTitle}
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className="short-desc mx-w-auto"
                                        dangerouslySetInnerHTML={{
                                          __html: section.body,
                                        }}
                                      ></div>
                                      {(section?.firstButtonLink ||
                                        section?.secondButtonLink) && (
                                        <div className="action mt-4">
                                          {section?.firstButtonLink && (
                                            <a
                                              className="btn btn-danger text-white px-4 mr-2"
                                              href={section.firstButtonLink}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {section.firstButtonText
                                                ? section.firstButtonText
                                                : "Click Here"}
                                            </a>
                                          )}
                                          {section?.secondButtonLink && (
                                            <a
                                              className="btn btn-outline-dark px-4"
                                              href={section?.secondButtonLink}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {section?.secondButtonText
                                                ? section?.secondButtonText
                                                : "Click Here"}
                                            </a>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          )}
                          {section.layout === "2" && (
                            <section
                              key={section.id}
                              className={`info-section px-lg-0 mb-4 ${
                                index % 2 === 0 &&
                                pageContainerData?.sections?.length > 1
                                  ? "bg-light py-80"
                                  : "bg-white"
                              }`}
                              id="infoLeftRightSection"
                            >
                              <div className="container">
                                <div className="row align-items-center g-5">
                                  <div
                                    className={`${
                                      section?.smallImage
                                        ? "col-lg-9 pr-lg-4"
                                        : "col-lg-6 pr-lg-5"
                                    } d-flex align-items-center mb-4`}
                                  >
                                    <div className="text px-0">
                                      <div className="head mx-w-auto">
                                        <h3
                                          className={`text-dark fs-30 fw-700 ${
                                            section?.subTitle ? "mb-1" : ""
                                          }`}
                                        >
                                          {section.title}
                                        </h3>
                                        {section?.subTitle && (
                                          <p className="text-main fs-15 fw-500">
                                            {section?.subTitle}
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className="short-desc mx-w-auto"
                                        dangerouslySetInnerHTML={{
                                          __html: section.body,
                                        }}
                                      ></div>
                                      {(section?.firstButtonLink ||
                                        section?.secondButtonLink) && (
                                        <div className="action mt-4">
                                          {section?.firstButtonLink && (
                                            <a
                                              className="btn btn-danger text-white px-4 mr-2"
                                              href={section.firstButtonLink}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {section.firstButtonText
                                                ? section.firstButtonText
                                                : "Click Here"}
                                            </a>
                                          )}
                                          {section?.secondButtonLink && (
                                            <a
                                              className="btn btn-outline-dark px-4"
                                              href={section?.secondButtonLink}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {section?.secondButtonText
                                                ? section?.secondButtonText
                                                : "Click Here"}
                                            </a>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`${
                                      section?.smallImage
                                        ? "col-lg-3"
                                        : "col-lg-6"
                                    }`}
                                  >
                                    <div className="image text-center">
                                      <img
                                        className="img img-fluid rounded"
                                        src={section.photo ? section.photo : ""}
                                        alt="Central Holidays"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          )}
                        </>
                      )}
                      {section?.isPackageSection && (
                        <div className="container">
                          <div className="row">
                            {section?.packages?.map((pack) => {
                              return (
                                <div
                                  key={pack.id}
                                  className=" col-md-6 col-lg-4"
                                >
                                  <div className="special-item">
                                    <figure className="special-img">
                                      <Link
                                        href={`/package/${pack.pageURL}`}
                                        passHref
                                      >
                                        <img
                                          height="250px"
                                          width="100%"
                                          src={
                                            pack.image
                                              ? pack.image
                                              : "https://www.centralholidays.com/MyUpload/1023201918184202020PACk850x530mag1.jpg"
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          alt={pack.name}
                                        />
                                      </Link>
                                    </figure>
                                    <div className="badge-dis">
                                      <span>
                                        <p
                                          style={{
                                            paddingTop: "10px",
                                            fontSize: "12px",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {pack.days} Days{" "}
                                        </p>
                                      </span>
                                    </div>
                                    <div className="special-content">
                                      <h4>
                                        <Link href={`/package/${pack.pageURL}`}>
                                          {pack.name}
                                        </Link>
                                      </h4>
                                      {pack.startingFrom != null &&
                                        pack.startingFrom.length > 0 &&
                                        pack.startingFrom !== "0" && (
                                          <div className="package-price mb-2">
                                            From:{" "}
                                            <ins>
                                              {formatCurrencyWithoutFraction(
                                                pack.startingFrom
                                              )}
                                            </ins>
                                          </div>
                                        )}
                                      <Link href={`/package/${pack.pageURL}`}>
                                        <a className="button-primary">
                                          Read More
                                        </a>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}

                {featuresData.map((featureData, i) => {
                  return (
                    <>
                      {/*////////// Testimonial ////////////*/}

                      {featureData.pageFeatureDataId ===
                        pageContainerData.featureNames[i] &&
                        featureData.testimonial && (
                          <>
                            <Testimonial testimonial={featureData} />
                          </>
                        )}

                      {/*////////// Brochure ////////////*/}

                      {featureData.pageFeatureDataId ===
                        pageContainerData.featureNames[i] &&
                        featureData.brochure && (
                          <>
                            <div className="container">
                              <div className="row">
                                {featureData.data.map((brochure) => {
                                  return (
                                    <>
                                      {brochure.isActive == true && (
                                        <Brochures
                                          key={brochure.id}
                                          brochure={brochure}
                                        />
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}

                      {/*////////// Find Agent ////////////*/}

                      {featureData.pageFeatureDataId ===
                        pageContainerData.featureNames[i] &&
                        featureData.FindAgent && (
                          <>
                            <FindAgent />
                          </>
                        )}
                      {/*////////// Careers ////////////*/}

                      {featureData.pageFeatureDataId ===
                        pageContainerData.featureNames[i] &&
                        featureData.job && (
                          <>
                            <div className="container">
                              <Careers
                                alldata={featureData}
                                pageUrlModule={pageUrlModule}
                              />
                            </div>
                          </>
                        )}
                      {featureData.pageFeatureDataId ===
                        pageContainerData.featureNames[i] &&
                        featureData.specialoffer && (
                          <>
                            <Special specials={featureData} />
                          </>
                        )}
                      {featureData.pageFeatureDataId ===
                        pageContainerData.featureNames[i] &&
                        featureData.contactus && (
                          <>
                            <Contact contact={featureData} />
                          </>
                        )}
                      {featureData.pageFeatureDataId ===
                        pageContainerData.featureNames[0] &&
                        featureData.Terms && (
                          <>
                            <Term />
                          </>
                        )}
                      {featureData.pageFeatureDataId ===
                        pageContainerData.featureNames[0] &&
                        featureData.RequestAQuote && (
                          <>
                            <Request />
                          </>
                        )}
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </main>
        {/* <!-- package subscribe details section html start --> */}
        <SubscribeSection />
        {/* <!-- package contact details section html start --> */}
        {/* <ContentSection /> */}
        {/* <!-- package client section html start --> */}
        <ClientSection />
      </div>
    </>
  );
}

export async function getServerSideProps(context) {
  let props = {};

  var pageUrlModule = context.params.page;

  const pageUrlContainerRes = await axiosconfig.get("website/pagecontainer", {
    params: {
      pageUrl: `${context.params.page}`,
    },
  });
  const pageUrl = await pageUrlContainerRes.data;

  const pageContainerRes = await axiosconfig.get("website/pagecontainer", {
    params: {
      PageModuleId: pageUrl?.data[0]?.pageModuleId,
      PageSize: 40,
    },
  });
  const pages = await pageContainerRes.data;
  var pageContainerData = {};
  var subpageContainerData = [];
  for (var pageContainer of pages.data) {
    if (pageContainer.hasParent === false) {
      pageContainerData = pageContainer;
    } else {
      subpageContainerData.push(pageContainer);
    }
  }
  const pageFeaturesRes = await axiosconfig.get("website/pagefeature", {
    params: {
      PageSize: 40,
    },
  });
  const pageFeaturesData = await pageFeaturesRes.data;

  var featuresRes = [];
  if (
    pageContainerData?.featureNames !== undefined &&
    pageContainerData?.featureNames.length > 0
  ) {
    for (const pageContainerfeature of pageContainerData.featureNames) {
      for (const pageFeatureData of pageFeaturesData.data) {
        if (
          pageContainerfeature === pageFeatureData.id &&
          pageContainerfeature !== "629a1d840771f1c6003e4634" &&
          pageContainerfeature !== "62e7759ea04001952527e306" &&
          pageContainerfeature !== "630b0f643a5108ef163f5a1d"
        ) {
          await axiosconfig
            .get(`website/${pageFeatureData.name}`, {
              params: {
                Status: "accepted",
                IsActive: true,
              },
            })
            .then((res) => {
              const data = {
                ...res.data,
                pageFeatureDataId: pageFeatureData.id,
                [pageFeatureData.name]: true,
              };
              featuresRes = [...featuresRes, data];
            });
        }
        if (pageContainerfeature === "629a1d840771f1c6003e4634") {
          const data = {
            pageFeatureDataId: pageFeatureData.id,
            [pageFeatureData.name]: true,
          };
          featuresRes = [...featuresRes, data];
        }
        if (pageContainerfeature === "62e7759ea04001952527e306") {
          const data = {
            pageFeatureDataId: pageFeatureData.id,
            [pageFeatureData.name]: true,
          };
          featuresRes = [...featuresRes, data];
        }
        if (pageContainerfeature === "630b0f643a5108ef163f5a1d") {
          const data = {
            pageFeatureDataId: pageFeatureData.id,
            [pageFeatureData.name]: true,
          };
          featuresRes = [...featuresRes, data];
        }
      }
    }
  }
  const featuresData = (await featuresRes) || [];

  return {
    props: {
      pageContainerData,
      featuresData,
      subpageContainerData,
      pageUrlModule,
    },
  };
}
